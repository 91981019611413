import Vue from 'vue'
import App from './App.vue'
import router from './router'

import dayjs from "dayjs";
Vue.prototype.$dayjs = dayjs;

import "../src/app.css";

Vue.config.productionTip = false

const certUrl = "https://inmunocel-api-4ou4hnx7xq-uc.a.run.app";
const prodUrl = "https://inmunocel-portal.uc.r.appspot.com";
const localUrl = "http://localhost:3000";

// Dejar en false en deployments
const forceLocalhost = false;

Vue.prototype.$apiUrl = forceLocalhost ? localUrl : process.env.NODE_ENV === "production" ? prodUrl : certUrl;

new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
