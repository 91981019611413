<template>
    <div class="page">
        <h1 v-if="loading" style="text-align: center;">Cargando...</h1>
        <div class="card" v-else-if="Object.values(admissionExam).length">
            <h2 style="text-align: center; margin-top: 3px; margin-bottom: 3px">{{ admissionExam.exam.name || "Examen válido" }}</h2>

            <br>

            <p style="font-size: 20px; line-height: 17px">
                <small class="translation"><i>Patient information</i></small><br>
                Datos del paciente
            </p>

            <div>
                <div class="translation-outer">
                    <small class="translation"><i>ID</i></small><br>
                    <b>{{ docs[admissionExam.admission.patient.document_type] || "Documento" }}</b>: {{ formatDocument(admissionExam.admission.patient) }}
                </div>

                <div class="translation-outer">
                    <small class="translation"><i>Name</i></small><br>
                    <b>Nombre</b>: {{ admissionExam.admission.patient.name || "-" }}
                </div>

                <div class="translation-outer">
                    <small class="translation"><i>Last name</i></small><br>
                    <b>Apellido paterno</b>: {{ admissionExam.admission.patient.lastname_f || "-" }}
                </div>

                <div class="translation-outer">
                    <small class="translation"><i>Last name</i></small><br>
                    <b>Apellido materno</b>: {{ admissionExam.admission.patient.lastname_m || "-" }}
                </div>

                <div class="translation-outer">
                    <small class="translation"><i>Gender</i></small><br>
                    <b>Sexo</b>: {{ patientGender || "-" }}
                </div>

                <div class="translation-outer">
                    <small class="translation"><i>Date of birth</i></small><br>
                    <b>Fecha de nacimiento</b>: {{ $dayjs(admissionExam.admission.patient.birth_date, "YYYY-MM-DD").format("DD/MM/YYYY") || "-" }}
                </div>
            </div>

            <p style="font-size: 20px; line-height: 17px">
                <small class="translation"><i>Test information</i></small><br>
                Datos del examen
            </p>
            <div>
                <div class="translation-outer">
                    <small class="translation"><i>Test type</i></small><br>
                    <b>Tipo de examen</b>: {{ admissionExam.exam.name || "-" }}
                </div>

                <div class="translation-outer">
                    <small class="translation"><i>Report type</i></small><br>
                    <b>Tipo de reporte</b>: {{ examReportType || "-" }}
                </div>

                <div class="translation-outer">
                    <small class="translation"><i>Received</i></small><br>
                    <b>Fecha de ingreso</b>: {{ formatDate(admissionExam.createdAt) || "-" }}
                </div>

                <div class="translation-outer">
                    <small class="translation"><i>Reported</i></small><br>
                    <b>Fecha de informe</b>: {{ formatDate(getFechaFirmado(admissionExam)) || "-" }}
                </div>

                <div class="translation-outer">
                    <small class="translation"><i>Work order</i></small><br>
                    <b>Órden de trabajo</b>: {{ admissionExam.ot && admissionExam.ot.code || "-" }}
                </div>

                <br>

                <div class="translation-outer">
                    <small class="translation"><i>Results</i></small><br>
                    <b>Resultados</b>:
                </div>
                <span v-if="resultAsPdf">Resultado PDF</span>
                <span v-else-if="!checkExamenEstaAlMenosFirmado(admissionExam)">Examen pendiente de firma</span>
                <span v-else v-html="getResultsList(getResults(admissionExam))"></span>

            </div>
        </div>
        <h1 v-else-if="code" style="text-align: center;">
            El código QR escaneado es inválido <span style="color: red;">&#4030;</span>
        </h1>
    </div>
</template>

<script>
import axios from "axios";
import { format } from "rut.js";

export default {
    name: "Validate",

    data() {
        return {
            code: this.$route.query.code,

            url: `${this.$apiUrl}/verify`,

            admissionExam: {},
            units: [],
            loading: false,

            format,

            docs: {
                1: "RUN",
                2: "Pasaporte",
                3: "DNI",
            }
        };
    },

    computed: {
        examReportType() {
            if (!this.admissionExam || !this.admissionExam.exam_result.length) {
                return null;
            }

            return this.admissionExam.exam_result[0].report_type;
        },

        patientGender() {
            const _getGenderById = (id) => {
                const genders = {
                    1: "Femenino",
                    2: "Masculino",
                };

                return genders[id];
            };

            if (!this.admissionExam.admission.patient.gender) {
                return null;
            }

            return _getGenderById(this.admissionExam.admission.patient.gender);
        },

        resultAsPdf() {
            if (!Object.values(this.admissionExam || {}).length) return false;

            return this.admissionExam.exam.result_as_pdf;
        }
    },

    async mounted() {
        if (this.code && this.code.length) {
            this.loading = true;

            try {
                const responses = await Promise.all([
                    axios.get(`${this.url}?code=${this.code}`),
                    axios.get(`${this.$apiUrl}/units`),
                ]);

                this.admissionExam = responses[0].data;
                this.units = responses[1].data.data;
            } catch (error) {
                console.error(error);
            } finally {
                this.loading = false;
            }
        }
    },

    methods: {
        // to do: sacar la fecha firmado based on status_id_{reportType} (not sure)
        getFechaFirmado(admissionExam) {
            if (!admissionExam.status_log) {
                return "";
            }

            const logsFirmados = admissionExam.status_log.filter((sl) => sl.exam_status_meta.id === 5);

            if (logsFirmados.length > 1) {
                // Buscar el último
                const latestFirmado = logsFirmados.reduce((a, b) => {
                    return new Date(a.when) > new Date(b.when) ? a : b;
                });

                return latestFirmado.when;
            } else if (logsFirmados.length === 1) {
                return logsFirmados[0].when;
            } else {
                return "";
            }
        },

        formatDate(date) {
            if (!date || !date.length) {
                return "-";
            }

            return this.$dayjs(date, "YYYY-MM-DD HH:mm:ss").format("DD/MM/YYYY HH:mm:ss");
        },
        formatDocument(patient) {
            if (patient.document_type === 1) {
                return this.format(patient.document_number);
            }

            return patient.document_number;
        },

        checkExamenEstaAlMenosFirmado(admissionExam) {
            const _getTipoInformeAbbreviation = (reportType) => {
                const tiposInforme = {
                    "Informe preliminar": "preliminar",
                    "Informe final": "final",
                    "Informe complementario": "compl",
                };

                return tiposInforme[reportType];
            };

            const reportType = admissionExam.exam_result[0].report_type;
            if (!reportType) return false;

            const statusIdByTipoInforme = admissionExam[`status_id_${_getTipoInformeAbbreviation(reportType)}`];

            return statusIdByTipoInforme ? (statusIdByTipoInforme >= 5 && statusIdByTipoInforme < 8) : false;
        },

        getResults(admissionExam) {
            if (!admissionExam.exam_result.length) {
                return [];
            }

            const isCollection = admissionExam.exam.associations.length > 1;

            const results = [];

            if (isCollection) {
                admissionExam.exam_result[0].details.forEach(detail => {
                    const associatedExam = admissionExam.exam.associations.find((a) => a.associated_exam.id === detail.exam_id);
                    const resultTypes = associatedExam.result_types;

                    const isCualitativo = resultTypes.findIndex((rt) => rt.result_type === 1) !== -1;
                    const isCuantitativo = resultTypes.findIndex((rt) => rt.result_type === 2) !== -1;

                    let result = null;
                    let unit = null;

                    if (isCualitativo) {
                        result = detail.value;
                    } else if (isCuantitativo) {
                        result = detail.numerical_value;
                        unit = this.getUnitName(detail.unit);
                    }

                    const examName = associatedExam.associated_exam.name;

                    results.push(`<b>${examName}</b>: ${result} ${unit || ''}`.trim());
                });
            } else {
                const isCualitativo = admissionExam.exam.result_types.findIndex((rt) => rt.result_type === 1) !== -1;
                const isCuantitativo = admissionExam.exam.result_types.findIndex((rt) => rt.result_type === 2) !== -1;

                const detail = admissionExam.exam_result[0].details[0];

                let result = null;

                if (isCualitativo) {
                    result = detail.result;
                } else if (isCuantitativo) {
                    result = detail.numerical_result;
                } else {
                    // Free text
                    if (detail.value){
                        result = detail.value;
                    } else if (detail.result){
                        result = detail.result;
                    }
                }

                results.push(`<b>${admissionExam.exam.name}</b>: ${result}`);
            }

            return results;
        },

        getUnitName(id) {
            if (!id || !id.length) return "";

            const formattedId = Number(id);
            if (Number.isNaN(formattedId)) return "";

            const unit = this.units.find(u => u.id === formattedId)
            if (!unit) return "Unidad no identificada";

            return unit.name;
        },

        getResultsList(results) {
            let list = "<ul>";

            results.forEach((result) => {
                list += `<li>${result}</li>`;
            });

            list += "</ul>";

            return list;
        },
    }
};
</script>

<style scoped>
@media only screen and (max-width: 600px) {
    .card {
        width: 93vw;
        padding: 22px;
    }
}

@media only screen and (min-width: 600px) {
    .card {
        width: 550px;
        padding: 40px;
    }
}

.card {
    position: relative;

    border-radius: 20px 20px 20px 20px;

    margin: auto;

    background-color: rgba(255, 255, 255, 0.856);

    box-shadow: 0 4px 10px 0 rgba(0,0,0,0.2);
    transition: 0.3s;

    margin-bottom: 65px;
    z-index: 1;
}

.card:hover {
    box-shadow: 0 8px 20px 0 rgba(0,0,0,0.2);
    background-color: rgba(255, 255, 255, 0.788);
}

.translation {
    color: rgb(115, 115, 115);
}

.translation-outer {
    line-height: 15px;
    margin-bottom: 3px;
}
</style>